export const themeList = [
  {
    id: 1,
    name: "default Theme",
    isActive: true,
    createdAt: "2023-03-10T13:41:49.211Z",
    updatedAt: "2023-03-10T13:41:49.211Z",
    elements: {
      type: "light",
      direction: "rtl",
      fontFamily: {
        ar: "kufi",
        en: "Elsie",
      },
      fontWeight: { xs: 200, s: 400, m: 500, l: 600, ll: 700 },
      fontSize: {
        xxs: 12,
        xs: 13,
        sm: 14,
        s: 16,
        m: 18,
        g: 36,
        l: 45,
        xxl: 60,
        btn: 14,
        title: 30,
        number: 28,
        medium: 24,
        summary: 16,
      },
      fontSizeG: [12, 60, 13, 30, 14, 16, 18, 45, 36, 14, 24, 28, 16],
      colors: {
        steel: "#244b5a8a",
        white: "#fff",
        darkTeal: "#244B5A",
        sliderdesc: "#344B61",
        menuText: "#272727",
        borderLinks: "#C4C4C4",
        footerTheme: "#fff",
        ratingColor: "rgba(0, 0, 0, 0.26)",
        backMedia: "#F7F7F7",
        cirecleError: "#E66C6C",
        primary: "#1A1A1A",
        dark: "#0A1929",
        buttonDark: "#244B5A",
        lightBorder: "#EEE0CF",
        tail: "#244B5A",
        contentBackground: "#6BC4E830",
        tabColor: "#B8C3CE",
        cardBackground: "#F9F9F9",
        notificationTitle: "#244B5A",
        floatingCloseBack: "#F0B25E",
        lightBlueBackground: "#63C3EF1A",
        cardContent: "#fff",
        card: "#fff",
        field: "#fff",
        black: "#272727",
        bgWhite: "#fff",
        TabColor: "#86929D",
        lightgray: "#F2F2F2",
        navBarback: "#F4F4F3",
        golden: "#B79264",
        offWhite: "#f3f3f3",
        blue: "#263661",
        darkBlue: "#244B5A",
        QuickBox: "#F3F8FA",
        lightBlue: "#6BC4E8",
        footer: "#F3FCFF",
        red: "#E51E1E",
        blackCard: "#2F2F2F",
        Gold: "#F2ECE0",
        navBarItem: "#585858",
        darkBlack: "#272727",
        gray: "#565656",
        blackMenu: "#000000",
        lightGray: "#E5E5E5",
        arrowTable: "rgba(0, 0, 0, 0.54)",
        darkgray: "#787878",
        youTubeIcon: "#FF0000",
        PhoneIcon: "#DC4569",
        TwitterIcon: "#55ACEE",
        facebookIcon: "#395185",
        websiteIcon: "#939EBD",
        customerIcon: "#B2C900",
        lightGold: "#F2ECE0",
        lightGoldCard: "#F2ECE0",
        arrowColor: "#343840",
        label: "#1F242F",
        labelSuggestion: "#1F242F",
        emailIcon: "#BFC2C8",
        lightGolden: "#F2ECE0",
        imageBorder: "#F5F5F5",
        border: "#C1C1C1",
        textField: "#E8E6E6",
        grayShadow:
          "radial-gradient(50% 50% at 50% 50%, #C7C7C7 0%, #E9E9E9 100%)",
        green: "#0B9A44",
      },
    },
  },
  {
    id: 2,
    name: "Light Blue Theme",
    isActive: true,
    createdAt: "2023-03-10T13:41:49.211Z",
    updatedAt: "2023-03-10T13:41:49.211Z",
    elements: {
      type: "light",
      direction: "rtl",
      fontFamily: {
        ar: "kufi",
        en: "Elsie",
      },
      fontWeight: { xs: 200, s: 400, m: 500, l: 600, ll: 700 },
      fontSize: {
        xxs: 12,
        xs: 13,
        sm: 14,
        s: 16,
        m: 18,
        g: 36,
        l: 45,
        xxl: 60,
        btn: 14,
        title: 30,
        number: 28,
        awarenessTitle: 24,
        summary: 16,
      },
      fontSizeG: [12, 60, 13, 30, 14, 16, 18, 45, 36, 14, 24, 28, 16],
      colors: {
        steel: "#244b5a8a",
        white: "#fff",
        tail: "#2A6D82",
        cardContent: "#fff",
        blackMenu: "#000000",

        menuText: "#272727",
        lightBlueBackground: "#63C3EF1A",
        TabColor: "#86929D",
        notificationTitle: "#2A6D82",
        backMedia: "#F7F7F7",
        tabColor: "#B8C3CE",
        ratingColor: "rgba(0, 0, 0, 0.26)",
        primary: "#2A6D82",
        buttonDark: "#2A6D82",
        lightBorder: "#EEE0CF",
        dark: "#0A1929",
        borderLinks: "#C4C4C4",
        sliderdesc: "#344B61",
        cirecleError: "#E66C6C",
        floatingCloseBack: "#F0B25E",
        lightgray: "#F2F2F2",
        navBarback: "#F4F4F3",
        offWhite: "#f3f3f3",
        field: "#fff",
        bgWhite: "#fff",
        golden: "#B79264",
        border: "#C1C1C1",
        Gold: "#263661",
        blue: "#263661",
        darkBlue: "#244B5A",
        lightBlue: "#6BC4E8",
        footer: "#F3FCFF",
        card: "#fff",
        QuickBox: "#F3F8FA",
        red: "#E51E1E",
        navBarItem: "#585858",
        black: "#2F2F2F",
        blackCard: "#2F2F2F",
        darkBlack: "#272727",
        gray: "#565656",
        lightGray: "#E5E5E5",
        darkgray: "#787878",
        youTubeIcon: "#FF0000",
        PhoneIcon: "#DC4569",
        TwitterIcon: "#55ACEE",
        facebookIcon: "#395185",
        websiteIcon: "#939EBD",
        customerIcon: "#B2C900",
        lightGold: "#F2ECE0",
        lightGoldCard: "#F2ECE0",
        arrowColor: "#343840",
        label: "#1F242F",
        labelSuggestion: "#1F242F",
        emailIcon: "#BFC2C8",
        lightGolden: "#F2ECE0",
        imageBorder: "#F5F5F5",
        arrowTable: "rgba(0, 0, 0, 0.54)",
        grayShadow:
          "radial-gradient(50% 50% at 50% 50%, #C7C7C7 0%, #E9E9E9 100%)",
        green: "#0B9A44",
      },
    },
  },
  {
    id: 3,
    name: "Dark Blue Theme",
    isActive: true,
    createdAt: "2023-03-10T13:41:49.211Z",
    updatedAt: "2023-03-10T13:41:49.211Z",
    elements: {
      type: "light",
      direction: "rtl",
      fontFamily: {
        ar: "kufi",
        en: "Elsie",
      },
      fontWeight: { xs: 200, s: 400, m: 500, l: 600, ll: 700 },
      fontSize: {
        xxs: 12,
        xs: 13,
        sm: 14,
        s: 16,
        m: 18,
        g: 36,
        l: 45,
        xxl: 60,
        btn: 14,
        title: 30,
        number: 28,
        awarenessTitle: 24,
        summary: 16,
      },
      fontSizeG: [12, 60, 13, 30, 14, 16, 18, 45, 36, 14, 24, 28, 16],
      colors: {
        steel: "#244b5a8a",
        darkTeal: "#244B5A",
        white: "#fff",
        cardContent: "#fff",
        tail: "#1A447B",
        border: "#C1C1C1",
        ratingColor: "rgba(0, 0, 0, 0.26)",
        primary: "#1A447B",
        blackMenu: "#000000",
        buttonDark: "#1A447B",
        TabColor: "#86929D",
        lightBorder: "#EEE0CF",
        lightBlueBackground: "#63C3EF1A",
        black: "#272727",
        floatingCloseBack: "#F0B25E",
        backMedia: "#F7F7F7",
        cirecleError: "#E66C6C",
        dark: "#0A1929",
        borderLinks: "#C4C4C4",
        notificationTitle: "#1A447B",
        tabColor: "#B8C3CE",
        sliderdesc: "#344B61",
        lightgray: "#F2F2F2",
        navBarback: "#F4F4F3",
        card: "#fff",
        offWhite: "#f3f3f3",
        menuText: "#272727",
        field: "#fff",
        bgWhite: "#fff",
        golden: "#B79264",
        Gold: "#263661",
        blue: "#263661",
        darkBlue: "#244B5A",
        lightBlue: "#6BC4E8",
        footer: "#F3FCFF",
        QuickBox: "#F3F8FA",
        red: "#E51E1E",
        navBarItem: "#585858",
        blackCard: "#2F2F2F",
        darkBlack: "#272727",
        gray: "#565656",
        lightGray: "#E5E5E5",
        darkgray: "#787878",
        youTubeIcon: "#FF0000",
        PhoneIcon: "#DC4569",
        TwitterIcon: "#55ACEE",
        facebookIcon: "#395185",
        websiteIcon: "#939EBD",
        customerIcon: "#B2C900",
        lightGold: "#F2ECE0",
        lightGoldCard: "#F2ECE0",
        arrowColor: "#343840",
        label: "#1F242F",
        labelSuggestion: "#1F242F",
        emailIcon: "#BFC2C8",
        lightGolden: "#F2ECE0",
        imageBorder: "#F5F5F5",
        arrowTable: "rgba(0, 0, 0, 0.54)",
        grayShadow:
          "radial-gradient(50% 50% at 50% 50%, #C7C7C7 0%, #E9E9E9 100%)",
        green: "#0B9A44",
      },
    },
  },
  {
    id: 4,
    name: "Blind Theme",
    isActive: true,
    createdAt: "2023-03-10T13:41:49.211Z",
    updatedAt: "2023-03-10T13:41:49.211Z",
    elements: {
      type: "blind",
      direction: "rtl",
      fontFamily: {
        ar: "kufi",
        en: "Elsie",
      },
      fontWeight: { xs: 200, s: 400, m: 500, l: 600, ll: 700 },
      fontSize: {
        xxs: 12,
        xs: 13,
        sm: 14,
        s: 16,
        m: 18,
        g: 36,
        l: 45,
        xxl: 60,
        btn: 14,
        title: 30,
        number: 28,
        awarenessTitle: 24,
        summary: 16,
      },
      fontSizeG: [12, 60, 13, 30, 14, 16, 18, 45, 36, 14, 24, 28, 16],
      colors: {
        steel: "#244b5a8a",
        white: "#fff",
        cardContent: "#fff",
        lightgray: "#F2F2F2",
        primary: "#244B5A",
        border: "#C1C1C1",
        blackMenu: "#000000",
        buttonDark: "#244B5A",
        lightBorder: "#EEE0CF",
        ratingColor: "rgba(0, 0, 0, 0.26)",
        tail: "#244B5A",
        menuText: "#272727",
        sliderdesc: "#344B61",
        floatingCloseBack: "#F0B25E",
        backMedia: "#F7F7F7",
        offWhite: "#f3f3f3",
        card: "#fff",
        tabColor: "#B8C3CE",
        navBarback: "#F4F4F3",
        golden: "#B79264",

        blue: "#263661",
        dark: "#0A1929",
        darkBlue: "#244B5A",
        darkTeal: "#244B5A",
        notificationTitle: "#244B5A",
        TabColor: "#86929D",
        lightBlueBackground: "#63C3EF1A",
        QuickBox: "#F3F8FA",
        lightBlue: "#6BC4E8",
        footer: "#F3FCFF",
        red: "#E51E1E",
        arrowTable: "rgba(0, 0, 0, 0.54)",
        field: "#fff",
        bgWhite: "#fff",
        navBarItem: "#585858",
        black: "#2F2F2F",
        blackCard: "#2F2F2F",
        darkBlack: "#272727",
        gray: "#565656",
        lightGray: "#E5E5E5",
        darkgray: "#787878",
        youTubeIcon: "#FF0000",
        PhoneIcon: "#DC4569",
        TwitterIcon: "#55ACEE",
        facebookIcon: "#395185",
        websiteIcon: "#939EBD",
        customerIcon: "#B2C900",
        lightGold: "#F2ECE0",
        lightGoldCard: "#F2ECE0",
        arrowColor: "#343840",
        label: "#1F242F",
        labelSuggestion: "#1F242F",
        emailIcon: "#BFC2C8",
        lightGolden: "#F2ECE0",
        imageBorder: "#F5F5F5",
        grayShadow:
          "radial-gradient(50% 50% at 50% 50%, #C7C7C7 0%, #E9E9E9 100%)",
        green: "#0B9A44",
      },
    },
  },
];
