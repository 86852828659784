import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import useStyles from "../../styles/components/navBar/navBarStyle";
import MenuBar from "./menuBar/MenuBar";
import clsx from "clsx";
import { Container } from "@mui/material";

function Header({ menuItems, configurations, configurationsSite }) {

  const classes = useStyles();
  const [visible, setVisible] = useState(false);


  useEffect(() => {
    let fromTop = document.documentElement.scrollTop;

    if (fromTop === 0) setVisible(false);
  }, [visible]);



  useEffect(() => {
    window.addEventListener(
      "scroll",
      function () {
        let fromTop = document.documentElement.scrollTop;
        if (fromTop === 0) setVisible(false);
        if (fromTop >= 40) setVisible(true);
      },
      false
    );
  }, []);

  return (

    <Box
      className={clsx(classes.scrollStyle, classes.headerBox, classes.positionBox)}
    >
      <Container maxWidth="xl" className={classes.headerContainer}>
        <MenuBar menuItems={menuItems} visible={visible} configurations={configurations} configurationsSite={configurationsSite} />
      </Container>

    </Box>

  );
}
export default Header;
