import { Box, IconButton, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { FaEarthAfrica } from "react-icons/fa6";
import { useTranslation } from "react-i18next";
import { FaXTwitter } from "react-icons/fa6";
import { MdContentCopy, MdOutlineEmail } from "react-icons/md";
import { RiFacebookCircleLine } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import NightsStayIcon from "@mui/icons-material/NightsStay";
import NightsStayOutlinedIcon from "@mui/icons-material/NightsStayOutlined";
import useStyles from "../../../styles/components/navBar/navBarStyle";
import { FaFacebook } from "react-icons/fa";
import { AiFillInstagram } from "react-icons/ai";
import { IoLogoTiktok } from "react-icons/io5";
import LanguageTab from "../navBar/navBarItem/LanguageTab";
import { themeList } from "../../../constants/theme";
import { changeTheme, setDarkMode } from "../../../redux/actionCreators/theme";
import { setDirection } from "../../../redux/actionCreators/theme";
import { switchLanguages } from "../../shared/utils";

function SocialMedia({ configurations }) {
  const classes = useStyles();
  const currentUrl = window.location.href;
  const { t, i18n } = useTranslation();

  const {
    theme_reducer: {
      basicTheme: { currentTheme, isRTL, isDark },
    },
  } = useSelector((state) => state);
  const [themes, setThemes] = useState([]);
  const [openLangTab, setOpenLangTab] = useState(false);
  const [show, setShow] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    setThemes(themeList);
  }, [isRTL]);
  let socialMediaLinks = [];
  if (
    configurations.length > 0 &&
    configurations.find(
      (item) => item?.key.includes("FACE_BOOK") && item?.value !== null
    )
  ) {
    const item =
      configurations.length > 0 &&
      configurations.find(
        (item) => item?.key.includes("FACE_BOOK") && item?.value !== null
      );
    socialMediaLinks.push({
      id: 1,

      icon: <FaFacebook round={true} color="primary" size={20} />,
      title: "facebook",
      value: item?.value,
    });
  }

  if (
    configurations.length > 0 &&
    configurations.find(
      (item) => item?.key.includes("INSTAGRAM") && item?.value !== null
    )
  ) {
    const item =
      configurations.length > 0 &&
      configurations.find(
        (item) => item?.key.includes("INSTAGRAM") && item?.value !== null
      );
    socialMediaLinks.push({
      id: 2,
      icon: <AiFillInstagram className="Instagram" />,
      title: "Instagram",
      value: item?.value,
    });
  }

  if (
    configurations.length > 0 &&
    configurations.find(
      (item) => item?.key.includes("TIK_TOK") && item?.value !== null
    )
  ) {
    const item =
      configurations.length > 0 &&
      configurations.find(
        (item) => item?.key.includes("TIK_TOK") && item?.value !== null
      );
    socialMediaLinks.push({
      id: 3,
      icon: <IoLogoTiktok />,
      title: "TIK_TOK",
      value: item?.value,
    });
  }

  const reversedArray = [...socialMediaLinks].reverse();
  const handleToggleLangTab = () => {
    setShow(true);
    setOpenLangTab(true);
  };
  const handleCloseLangTab = () => {
    setShow(false);
    setOpenLangTab(false);
  };

  function handleListKeyDownLangTab() {
    setOpenLangTab(true);
  }
  return (
    <Box className={classes.socialMedia}>
      {reversedArray?.map((item, index) => {
        return (
          <>
            {item?.title === "EMAIL" ? (
              <div
                className={item?.title}
                onClick={(e) => window.open(`mailto:${item?.value}`)}
                key={index}
              >
                {item?.icon}
              </div>
            ) : (
              <a className={item?.title} href={item?.value} key={index}>
                {item?.icon}
              </a>
            )}
          </>
        );
      })}
      <Box
        className={classes.containBox}
        onClick={(e) => {
          handleToggleLangTab();
          // switchLanguages();
          // dispatch(setDirection({ isRTL: !isRTL }));
          // dispatch(setLanguage({ language: isRTL ? "en" : "ar" }));
          // i18n.changeLanguage(isRTL ? "en" : "ar");
        }}
      >
        {/* <BootstrapTooltip title={t("HEADER.CHANGELANGUAGE")}> */}

        {/* <Box className={classes.icon}> */}
        <FaEarthAfrica
          color="primary"
          onClick={(e) => {
            switchLanguages();
            dispatch(setDirection({ isRTL: !isRTL }));
            // dispatch(setLanguage({ language: isRTL ? "en" : "ar" }));
            i18n.changeLanguage(isRTL ? "en" : "ar");
          }}
          className={classes.icon}
        />
        {/* <img src="/images/langg.png" /> */}
        {/* </Box> */}
        {/* </BootstrapTooltip> */}
        {/* <Typography className={classes.navBarTitle}>
                    {t("HEADER.LANGUAGES")}

                </Typography> */}

        {/* <LanguageTab
          open={openLangTab}
          handleClose={handleCloseLangTab}
          handleListKeyDown={handleListKeyDownLangTab}
        /> */}
      </Box>

      {/* <Box className={classes.nightButton}>
        <IconButton
          sx={{
            marginBlockStart: "-6px",
          }}
          variant="contained"
          onClick={(e) => {
            let current = localStorage.getItem("iconThemeTitle");
            current === "Blind Theme" &&
              !isDark &&
              themes &&
              dispatch(changeTheme(themes[0]));

            dispatch(setDarkMode({ isDark: !isDark }));
          }}
        >
          {isDark ? <NightsStayIcon /> : <NightsStayOutlinedIcon />}
        </IconButton>
      </Box> */}
    </Box>
  );
}
export default SocialMedia;
