import { makeStyles } from "@mui/styles";
const useStyles = makeStyles((theme) => ({
  menuRoot: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    [theme.breakpoints.down(670)]: {
      justifyContent: "start",
    },
  },
  headerContainer: {
    [theme.breakpoints.up(1200)]: {
      paddingInline: "40px!important",
    },
  },
  scrollStyle: {
    boxShadow: "0px 15px 19px -1px rgb(0 0 0 / 5%)",
    borderBottomLeftRadius: "20px",
    borderBottomRightRadius: "20px",
    marginInline: 25,
  },
  headerBox: {
    display: "flex",
    flexDirection: "column",
    height: 190,
    zIndex: "600",
  },
  // icon: {
  //   color: ` ${theme.palette.primary.main}!important`,
  //   transform: theme.direction == "ltr" ? "scaleX(-1)" : "inherit",
  //   fontSize: `${theme.globals.fontSize.sm + 6}px!important`,
  //   [theme.breakpoints.between(1170, 1200)]: {
  //     fontSize: `${theme.globals.fontSize.sm + 9}px!important`,
  //   },
  //   [theme.breakpoints.down(340)]: {
  //     fontSize: `${theme.globals.fontSize.sm + 2}px!important`,
  //   },
  // },

  menuIconBox: {
    display: "none",
    [theme.breakpoints.down(670)]: {
      display: "flex",
      "& .MuiIconButton-root:hover": {
        background: "none!important",
      },
    },
  },
  positionBox: {
    position: "fixed",
    top: 0,
    right: 0,
    left: 0,
    height: "fit-content",
    backgroundColor:
      theme.palette.type === "dark"
        ? `${theme.globals.colors.card}!important`
        : "#fbfbfb",
    zIndex: "600",
    minHeight: 75,
  },
  navBarTitle: {
    fontSize: `${theme.globals.fontSize.xs}px!important`,
    color: theme.globals.colors.navBarItem,
    marginRight: "4px!important",
    lineHeight: "17px",

    [theme.breakpoints.down(760)]: {
      display: "none!important",
    },
  },
  logoBox: {
    width: "80px",
    height: "100px",
    marginInlineEnd: 22,
    cursor: "pointer",
    marginTop: 6,
    marginBottom: 6,
    "& >img": {
      maxHeight: "100%",
    },
    [theme.breakpoints.down(1200)]: {
      width: "60px",
      height: "60px",
    },
  },
  containBox: {
    display: "flex",
    marginTop: "-4px",
    justifyContent: "center!important",
    alignItems: "center!important",
    cursor: "pointer",
    position: "relative",
    "&:hover": {
      backgroundColor: "transparent!important",
    },
    "& > svg": {
      fontSize: "22px!important",
      color:
        theme.palette.type === "dark"
          ? `${theme.globals.colors.field}!important`
          : `${theme.globals.colors.dark}!important`,
      marginInlineEnd: 5,
    },
  },
  nightButton: {
    "& button": {
      padding: "5px 0!important",
      color:
        theme.palette.type === "dark"
          ? `${theme.globals.colors.field}!important`
          : `${theme.globals.colors.dark}!important`,
    },
  },
  icon: {
    width: "20px",
    height: "20px",

    marginTop: -2,
    borderRadius: "50%",

    display: "flex!important",
    justifyContent: "center!important",
    alignItems: "center!important",
    //padding: "12px",
  },
  socialMedia: {
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down(670)]: {
      flex: 1,
      justifyContent: "flex-end",
    },
    "& > a": {
      "& > svg": {
        fontSize: "22px!important",
        color:
          theme.palette.type === "dark"
            ? `${theme.globals.colors.field}!important`
            : `${theme.globals.colors.dark}!important`,
        marginInlineEnd: 5,
      },
    },
  },
}));
export default useStyles;
