import { makeStyles } from "@mui/styles";
const useStyles = makeStyles((theme) => ({
  toolBar: {
    display: "flex",
    justifyContent: "space-between",
    height: "30px!important",
    minHeight: "30px!important",
    maxHeight: "30px!important",
    paddingLeft: "8px!important",
    paddingRight: "8px!important",
    paddingBlock: "25px 10px!important",
    marginBlockEnd: "8px!important",
  },

  menuIconBox: {
    display: "none",
    [theme.breakpoints.down(1170)]: {
      display: "flex",
      "& .MuiIconButton-root:hover": {
        background: "none!important",
      },
    },
  },

  menuRoot: {
    display: "flex",
    alignItems: "center",
    marginInlineStart: "25px",
    [theme.breakpoints.down(600)]: {
      marginInlineStart: "4px",
    },
  },

  boxIcon: {
    width: "30px",
    height: "30px",
    minHeight: "30px",
    minWidth: "30px",
    maxHeight: "30px",
    maxWidth: "30px",
    borderRadius: "50%",
    display: "flex!important",
    justifyContent: "center!important",
    alignItems: "center!important",
    transform: "scale(1)",

    transition: "transform 0.3s",
    [theme.breakpoints.between(1170, 1200)]: {
      width: "20px",
      height: "20px",
      minHeight: "20px",
      minWidth: "20px",
      maxHeight: "20px",
      maxWidth: "20px",
    },

    "&:hover": {
      transform: "scale(1.2)",
    },
  },

  icon: {
    color: ` ${theme.palette.primary.main}!important`,
    transform: theme.direction == "ltr" ? "scaleX(-1)" : "inherit",
    fontSize: `${theme.globals.fontSize.sm + 6}px!important`,
    [theme.breakpoints.between(1170, 1200)]: {
      fontSize: `${theme.globals.fontSize.sm + 4}px!important`,
    },
    [theme.breakpoints.down(340)]: {
      fontSize: `${theme.globals.fontSize.sm + 2}px!important`,
    },
  },

  tabBox: {
    display: "flex",
    marginInlineStart: "10px",

    [theme.breakpoints.down(1200)]: {
      marginInlineStart: "4px",
    },
    [theme.breakpoints.down(700)]: {
      display: "none",
    },
  },

  arrow: {
    "& button": {
      position: "relative",
      "&:after": {
        right: "50%",
        width: "16px",
        bottom: theme.palette.type === "dark" ? "-9px" : "-12px",
        height: "16px",
        content: '""',
        opacity: 0,
        zIndex: "100",
        position: "absolute",
        borderTop: "1px solid transparent",
        borderRight: `1px solid ${theme.palette.primary.main}`,
        borderLeft: "1px solid transparent",
        background: `${theme.globals.colors.card}!important`,
        transform: "rotate(45deg)",
        borderBottom: `1px solid ${theme.palette.primary.main}`,
      },
      "&:hover": {
        opacity: 1,
      },
    },
  },

  menuStyle: {
    position: "relative",
    "& *": {
      fontFamily:
        theme.direction === "rtl"
          ? `${theme.globals.fontFamily.ar}!important`
          : `${theme.globals.fontFamily.en}!important`,
    },
    listStyleType: "none",
    marginRight: "15px!important",

    "& li": {
      "& a": {
        "& *": {
          color:
            theme.palette.type === "dark"
              ? `${theme.globals.colors.field}!important`
              : `${theme.globals.colors.gray}!important`,
        },
      },
    },
    "& .MuiButton-root": {
      color: theme.palette.type === "dark"
        ? `${theme.globals.colors.field}!important` : `${theme.globals.colors.black}!important`,
      background: "none!important",
      whiteSpace: "nowrap",
      textTransform: "math-auto",
      fontSize: `${theme.globals.fontSize.m + 2}px!important`,
      fontWeight: `${theme.globals.fontWeight.m}!important`,
      height: "55px",
      padding: 6,
      [theme?.breakpoints?.down(1400)]: {
        fontSize: "18px!important",
      },
      [theme?.breakpoints?.down(1200)]: {
        fontSize: "16px!important",
      },
      [theme?.breakpoints?.down(900)]: {
        fontSize: "14px!important",
      },
      [theme?.breakpoints?.down(800)]: {
        fontSize: "12px!important",
      },
      [theme?.breakpoints?.down(750)]: {
        fontSize: "11px!important",
      },
      "& >span": {
        color: theme.palette.type === "dark"
          ? `${theme.globals.colors.field}!important`
          : `${theme.globals.colors.dark}!important`,


      }
      ,
      "& .MuiTouchRipple-root": {
        display: "none",
      },
    },
    "&:after": {
      position: 'absolute',
      content: '""',
      width: '60%',
      height: 3,
      backgroundColor: 'transparent',
      bottom: 0,
      transition: 'all 0.3s ease-out',
      right: 0,
      left: 0,
      margin: 'auto',
    },
    "& .subItemDiv": {
      opacity: "0",
    },
    "& >div .subItem": {
      opacity: "0",
      display: "none",
      "& >li:nth-last-child(1)": {
        borderBottom: "none!important",
        marginBottom: "2px!important",
      },
      "& li": {
        marginBottom: "4px",
        fontSize: `${theme.globals.fontSize.sm}px!important`,

        backgroundImage:
          theme.palette.type === "dark"
            ? `${theme.globals.colors.black}`
            : theme.direction === "rtl"
              ? `linear-gradient(to left, ${theme.palette.primary.main} 50%, ${theme.palette.primary.main} 50%, ${theme.globals.colors.black} 50%)`
              : `linear-gradient(to right, ${theme.palette.primary.main} 50%, ${theme.palette.primary.main} 50%,${theme.globals.colors.black} 50%)`,
        backgroundSize: "200% 100%",
        backgroundPosition: theme.direction === "rtl" ? "200%" : "-100%",
        display: "inline-block",
        padding: "5px 0",
        position: "relative",
        WebkitBackgroundClip: "text",
        WebkitTextFillColor:
          theme.palette.type === "dark"
            ? `${theme.palette.primary.white}!important`
            : "transparent",
        transition: "all 0.3s ease-in-out",
        "&:before": {
          content: '""',
          background: theme.palette.primary.main,
          display: "block",
          position: "absolute",
          bottom: "-3px",
          left: "0",
          width: "0",
          height: "2px",
          transition: "all 0.3s ease-in-out",
        },
        "&:hover": {
          backgroundPosition: 0,
          WebkitTextFillColor:
            theme.palette.type === "dark"
              ? `${theme.palette.primary.white}!important`
              : "transparent",
          "&:before": {
            width: "100%",
          },
          "& >.MuiGrid-root": {
            display: "flex",
            width: "100%",
            borderTop: `1px solid ${theme.palette.primary.main}`,
          },
        },
      },
      "& div > .gridSubItem": {
        "&.MuiGrid-root": {
          paddingTop: 0,
        },
        "& li": {
          "& a": {
            color:
              theme.palette.type === "dark"
                ? `${theme.globals.colors.field}!important`
                : `${theme.globals.colors.black}!important`,
          },
        },
        "& > .subItemchild": {
          display: "flex",
          flexDirection: "column",
          "& li": {
            paddingBlock: 6,
            marginBottom: 2,

            backgroundImage: "none!important",
            WebkitTextFillColor:
              theme.palette.type === "dark"
                ? `${theme.globals.colors.field}!important`
                : `${theme.globals.colors.gray}!important`,
            "&:before": {
              display: "none",
            },
            "&:hover": {
              WebkitTextFillColor: "none",
              "&:before": {
                width: "0%",
              },
            },

            "& > .subItemTextChild": {
              fontSize: `${theme.globals.fontSize.sm}px!important`,

              color:
                theme.palette.type === "dark"
                  ? `${theme.globals.colors.field}!important`
                  : `${theme.globals.colors.gray}!important`,
              paddingInlineStart: 2,
              "&:hover": {
                color: `${theme.globals.colors.green}!important`,
                WebkitTextFillColor: `${theme.globals.colors.green}!important`,
              },
            },
          },
        },
      },
      "& div > .gridSubItemChild": {
        "&.MuiGrid-root": {
          paddingTop: 0,
        },
        "& li": {
          backgroundImage: "none!important",
          backgroundColor:
            theme.palette.type === "dark"
              ? `${theme.globals.colors.black}`
              : `${theme.globals.colors.primary}!important`,
          "& a": {
            color:
              theme.palette.type === "dark"
                ? `${theme.globals.colors.field}!important`
                : `${theme.globals.colors.black}!important`,
          },
        },
        "& > .subItemchild": {
          display: "flex",
          flexDirection: "column",
          "& li": {
            paddingBlock: 6,
            marginBottom: 2,

            backgroundImage: "none!important",
            WebkitTextFillColor:
              theme.palette.type === "dark"
                ? `${theme.globals.colors.field}!important`
                : `${theme.globals.colors.gray}!important`,

            "&:before": {
              display: "none",
            },
            "&:hover": {
              WebkitTextFillColor: "none",
              "&:before": {
                width: "0%",
              },
            },

            "& > .subItemTextChild": {
              fontSize: `${theme.globals.fontSize.sm}px!important`,

              color:
                theme.palette.type === "dark"
                  ? `${theme.globals.colors.field}!important`
                  : `${theme.globals.colors.gray}!important`,
              paddingInlineStart: 2,
              "&:hover": {
                color: `${theme.globals.colors.green}!important`,
                WebkitTextFillColor: `${theme.globals.colors.green}!important`,
              },
            },
          },
        },
      },
    },
    "&:hover": {
      "&:after": {
        backgroundColor: theme.palette.type === "dark"
          ? `${theme.globals.colors.field}!important`
          : ` ${theme.palette.primary.main}!important`,
        width: "70%"

      },
      "& .MuiButton-root": {
        color: theme.palette.type === "dark"
          ? `${theme.globals.colors.field}!important`
          : ` ${theme.palette.primary.main}!important`,

        "& >span": {
          color: theme.palette.type === "dark"
            ? `${theme.globals.colors.field}!important`
            : ` ${theme.palette.primary.main}!important`,
        },

      },
      "& button": {
        "&:after": {
          opacity: 1,
        },
      },
      "& .subItemDiv": {
        opacity: "1",
        display: "flex!important",
        width: "100%",
        position: "absolute",
        backgroundColor: "transparent",
        // marginBlockStart: 10,
        borderRadius: "0px 0px 30px 30px",
        left: "0px",
      },
      "& div .subItem": {
        opacity: "1",
        display: "flex!important",
        width: "100%",
        position: "absolute",
        // marginBlockStart: 10,
        borderRadius: "0px 0px 30px 30px",

        padding: theme.palette.type === "dark" ? 17 : 20,
        left: "0px",
        backgroundColor: `${theme.globals.colors.white}`,
        boxShadow:
          "rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 7px -5px",
        paddingBottom: "0",
        "& >.MuiGrid-root": {
          display: "flex",
          width: "100%",
          borderTop: `1px solid ${theme.palette.primary.main}`,
          padding: 16,
        },
      },
    },
  },

  navBarItem: {
    display: "flex",
  },
}));
export default useStyles;
