import {
  Box, IconButton
} from "@mui/material";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import useStyles from "../../../styles/components/navBar/navBarStyle";
import SocialMedia from "../socialMedia/SocialMedia";
import MenuBarItem from "./menuBarItem/MenuBarItem";
import { RiMenu3Fill } from "react-icons/ri";

import { getElementByKey, getImageByKey } from "../../../publicFunctions";
import { useSelector } from "react-redux";
let lang = Boolean(JSON.parse(localStorage.getItem("isiconRTL")))
  ? "ar"
  : "en";


function MenuBar({ menuItems, visible, configurations, configurationsSite }) {

  const {
    theme_reducer: {
      basicTheme: { currentTheme, isRTL, isDark },
    },
  } = useSelector((state) => state);
  const fullLogoDark = typeof configurationsSite == "object" &&
    Object.keys(configurationsSite)?.length !== 0 &&
    configurationsSite?.site2 && configurationsSite?.site2.image && configurationsSite?.site2.image.uuid ?
    `/api/file/download/${configurationsSite?.site2.image.uuid}` : "/images/icon.png";
  const fullLogo = typeof configurationsSite == "object" &&
    Object.keys(configurationsSite)?.length !== 0 &&
    configurationsSite?.site1 && configurationsSite?.site1.image && configurationsSite?.site1.image.uuid ?
    `/api/file/download/${configurationsSite?.site1.image.uuid}` : "/eventsLogo.png";

  const [openMenu, setOpenMenu] = useState(false);
  const classes = useStyles();

  const handleClickOpenMenuBar = () => {
    setOpenMenu(true);
  };

  const handleCloseMenuBar = async () => {
    await setOpenMenu(false);
  };
  return (
    <Box className={classes.menuRoot}>
      <Link
        to={`/${lang}/home`}

      >
        <Box className={classes.logoBox}>
          <img src={isDark ? fullLogoDark : fullLogo} alt="fullLogo" />
        </Box>
      </Link>

      <MenuBarItem
        menuHeader={menuItems}


      />
      <SocialMedia configurations={configurations} />

    </Box>
  )
};
export default MenuBar;