import { Box, Button, Grid, Toolbar } from "@mui/material";
import clsx from "clsx";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, NavLink } from "react-router-dom";
import { uid } from "react-uid";
import useStyles from "../../../../styles/components/menuBar/menuBarStyle";



export default function MenuBar({ menuHeader, setIsShown, isShown, fullLogo }) {
    const [menuItem, setMenuItem] = useState();
    const [openMenu, setOpenMenu] = useState(false);

    const classes = useStyles();
    let lang = Boolean(JSON.parse(localStorage.getItem("isiconRTL")))
        ? "ar"
        : "en";







    useEffect(() => {
        if (menuHeader) {

            setMenuItem(
                menuHeader
            );
        }


    }, [menuHeader]);

    let content;
    if (menuItem?.length > 0)
        content = menuItem?.slice(0, 6).map((item) => {

            return (
                <>
                    <li
                        key={uid(item)}

                        className={clsx(
                            classes.menuStyle,
                            item?.childrens?.length > 0 ? classes.arrow : ""
                        )}
                    >
                        {item.adminType == "External" ?
                            <a
                                href={item.link}
                                target="_blank"
                                rel="noreferrer"
                                className="sliderLink"
                            >
                                <Button key={uid(item)}
                                    onClick={() => {


                                    }}>
                                    <span>{item.title}</span>
                                </Button>
                            </a>
                            :
                            <Button key={uid(item)}
                                onClick={() => {

                                    const homeElement = document.getElementById(item.link);
                                    let idHome = item.link;

                                    if (idHome == "home") {
                                        window.scrollTo({ top: 0, behavior: 'smooth' });
                                    }
                                    else if (homeElement) {

                                        homeElement.scrollIntoView({ behavior: 'smooth' });
                                    }
                                }}>
                                <span>{item.title}</span>
                            </Button>}


                    </li>
                </>
            );
        });
    return (
        <Toolbar disableGutters className={classes.toolBar}>

            <Box className={classes.menuRoot} id="tourMenu">

                <Box className={classes.tabBox}>{content}</Box>
            </Box>


        </Toolbar>
    );
}
